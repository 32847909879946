<template>
  <v-layout wrap align-center class="interval-time-main">
    <v-flex xs12>
      <v-icon @click="handleChangeValue(+1)" :disabled="max && max === data">      
        mdi-arrow-up-drop-circle-outline
      </v-icon>
    </v-flex>
    <v-flex xs12 class="interval-time-data" :style="{ 'color' : data === 0 ? 'lightgray' : 'white'}">
      <center>{{formatTime(data)}}</center>
    </v-flex>
    <v-flex xs12>
      <v-icon @click="handleChangeValue(-1)" :disabled="data === 0">
        mdi-arrow-down-drop-circle-outline
      </v-icon>
    </v-flex>
  </v-layout>
</template>

<script>
  import utils from '@/services/utils'
  export default {
    props: {
      value: {
        type: Number,
        required: true,
        default: 0
      },
      max: {
        type: Number,
        required: false,
        default: null
      }
    },
    data () {
      return {
        data: 0
      }
    },
    computed: {
    },
    watch: {
      value: {
        handler: function (newValue) {
          if (newValue != null) {
            this.data = newValue
          }
        },
        deep: true
      }
    },
    mounted (){
      this.data = this.value
    },
    methods: {
      formatTime (value) {
        return utils.pad(value)
      },
      handleChangeValue(value) {
        if (value < 0) {
          // resta
          if (this.data > 0) this.data += value       
        } else {
          // suma
          if (this.data < this.max || !this.max) this.data += value
        }
        this.$emit('input', this.data)
      }
    }
  }
</script>
<style scoped>
  .interval-time-main {
    text-align: center;
  }
  .interval-time-data {
    padding: 0 10px !important;
    margin: 0 10px;
    background-color: gray;
    color: white;
    border-radius: 5px;
    text-align: right;
    font-size: 20px;
    font-weight: 500;
  }
</style>
